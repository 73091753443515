import { useState, useEffect } from 'react';

function AdminMenuDuJour({ onLogout }) {
  const [menuDuJour, setMenuDuJour] = useState({});
  const token = localStorage.getItem('token')

  useEffect(() => {
    fetchMenuDuJour();
  }, []);

  const fetchMenuDuJour = () => {
    try {
      fetch('https://borsalino-392514.ew.r.appspot.com/menudujour')
        .then(response => response.json())
        .then(data => {
          const [firstMenu] = data.menuDuJour;
          setMenuDuJour(firstMenu);
        })
        .catch(error => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleFormSubmit = (event, menuId) => {
    event.preventDefault();

    const updatedPlat = {
      prix: event.target.elements.prix.value,
      entree: event.target.elements.entree.value,
      plat1: event.target.elements.plat1.value,
      plat2: event.target.elements.plat2.value,
      plat3: event.target.elements.plat3.value,
      dessert: event.target.elements.dessert.value,
    };

    try {
      fetch(`https://borsalino-392514.ew.r.appspot.com/menudujour/${menuId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedPlat),
      })
        .then(response => response.json())
        .then(data => {
          console.log(data.message);
          fetchMenuDuJour();
          alert("mise a jour reussie");
        })
        .catch(error => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='container-form-admin'>
      <h1 className='title'>Menu du jour</h1>
      <div className='container-form-page'>
        <form onSubmit={(event) => handleFormSubmit(event, menuDuJour._id)}>
          <label>
            prix :
            <input type="text" name="prix" defaultValue={menuDuJour.prix} />
          </label>
          <label>
            entree :
            <input type="text" name="entree" defaultValue={menuDuJour.entree} />
          </label>
          <label>
            plat 1 :
            <textarea name="plat1" defaultValue={menuDuJour.plat1}></textarea>
          </label>
          <label>
            plat 2 :
            <textarea name="plat2" defaultValue={menuDuJour.plat2}></textarea>
          </label>
          <label>
            plat 3 :
            <textarea name="plat3" defaultValue={menuDuJour.plat3}></textarea>
          </label>
          <label>
            dessert :
            <textarea name="dessert" defaultValue={menuDuJour.dessert}></textarea>
          </label>
          <button className="bouton-form" type="submit">Modifier</button>
        </form>
      </div>
      <button className="bouton-admin-page" onClick={onLogout}>Déconnexion</button>
    </div>
  );
}

export default AdminMenuDuJour;
