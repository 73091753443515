import { useState, useEffect } from 'react';

function MenuDuJour() {
    const [platsDuJour, setPlatsDuJour] = useState([]);
  
    useEffect(() => {
      fetch('https://borsalino-392514.ew.r.appspot.com/menudujour')
        .then(response => response.json())
        .then(data => {
          setPlatsDuJour(data.menuDuJour);
        })
        .catch(error => {
          console.error(error);
        });
    }, []);
  
    return (
      <div className='container-menudujour'>
        {platsDuJour.map((menu, index) => (
          <div className='menudujour' key={index}>
            <div className='row'>
              <h2>Menu du jour</h2>
              <span className='absolute'>{menu.prix} €</span>
            </div>
            <div className='entree'>
              <p>{menu.entree}</p>
            </div>
            <hr/>
            <div className='plat'>
              <p>{menu.plat1}</p>
              <p>{menu.plat2}</p>
              <p>{menu.plat3}</p>
            </div>
            <hr/>
            <div className='dessert'>
              <p>{menu.dessert}</p>
            </div>
          </div>
        ))}
      </div>
    );
}

export default MenuDuJour;