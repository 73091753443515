import React from 'react';
import { Link } from "react-router-dom";
import Map from "../component/map";
import Logo from "../assets/borsalino-logo.svg"
import Ardoise from "../assets/ardoise.png"
import MenuDuJour from '../component/menuDuJour';

function Accueil() {
    return (
        <div>
            <div className="container-accueil">
                <div className='container-img-logo'>
                    <img src={Logo} className="img-logo" alt=""/>
                </div>
                <div className='container-text'>
                    <h1>BORSALINO</h1>
                    <span>Nous vous accueillons sur place de 12h00 a 14h30</span>
                    <span>et de 19h00 a 22h00 du Lundi au Samedi</span>
                    <span>( Sauf le Mardi et Mercredi soir )</span>
                    <Link to="/menu" className='bouton-carte'>
                        <span>Voir notre carte</span>
                    </Link>
                    <div className="scroll-downs">
                        <div className="mousey">
                            <div className="scroller"></div>
                        </div>
                    </div>
                </div>
                <div className='container-img-ardoise'>
                    <img src={Ardoise} className="img-ardoise" alt=""/>
                    <div className='menu-overlay'>
                        <MenuDuJour/>
                    </div>
                </div>
            </div>
            <Map/>
        </div>
    )
}

export default Accueil