import React, { useRef, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Logo from "../assets/advisor-logo.png";
import AvisItem from './avisItemsA';
import { handleScrollLeft, handleScrollRight } from '../utils/scrollUtils';

const AvisAdvisor = ({ reviewsA, detailsA }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const extendedReviews = [...reviewsA, ...reviewsA];
    container.innerHTML = ''; // Efface le contenu initial

    extendedReviews.forEach((review) => {
      const listItem = document.createElement('li');
      listItem.className = 'avis';

      createRoot(listItem).render(<AvisItem review={review} />);

      container.appendChild(listItem);
    });

    const scrollInterval = setInterval(() => {
      const container = containerRef.current;
      if (container) {
        const containerWidth = container.offsetWidth;
        let scrollAmount;
        if (window.innerWidth <= 1200) {
          scrollAmount = containerWidth; // Utiliser 100% de la largeur du conteneur
        } else {
          scrollAmount = containerWidth * 0.5; // Utilisez le pourcentage souhaité ici
        }
        container.scrollBy({
          left: scrollAmount,
          behavior: 'smooth',
        });

        // Vérifiez si vous avez atteint la fin de la liste
        if (container.scrollLeft + containerWidth >= container.scrollWidth) {
          // Revenez au début de la liste
          container.scrollLeft = 0;
        }
      }
    }, 3500);

    return () => {
      clearInterval(scrollInterval);
    };
  }, [reviewsA]);

  return (
    <div className="container">
      <div className='container-advisor'>
        <img className="advisor-logo" src={Logo} alt="" />
        <div className='row-direction'>
          <img className="advisor-rating" src={detailsA.rating_image_url} alt=''/>
          <p className='note-avis'>{detailsA.rating}</p>
        </div>
      </div>
      <div className="container-avis">
            <button className="scroll-button left" onClick={() => handleScrollLeft(containerRef.current)}>
                <FaChevronLeft />
            </button>
            <ul className="avis-list scroll-container" ref={containerRef}>
                {reviewsA.map((review, index) => (
                    <AvisItem key={index} review={review} />
                ))}
            </ul>
            <button className="scroll-button right" onClick={() => handleScrollRight(containerRef.current)}>
                <FaChevronRight />
            </button>
        </div>
    </div>
  );
};

export default AvisAdvisor;
