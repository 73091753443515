import Iframe from 'react-iframe';
import Form from "../component/formulaire"

function Map() {
    return (
        <div className='container-map'>
            <div className='information'>
                <h2>Nous Contacter</h2>
                <span>Pour tout renseignement ou pour une reservation</span>
                <span>vous pouvez nous contacter par téléphone au 09 73 37 93 84</span>
                <span>ou en remplissant le formulaire ci-dessous.</span>
            </div>
            <div className='width-hundred'>
                <Iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2621.4824896593072!2d2.2081847066424607!3d48.92525128703838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66416ba684877%3A0x41273040135e955b!2sBorsalino!5e0!3m2!1sfr!2sfr!4v1680813734624!5m2!1sfr!2sfr"
                    className="map"
                    allowFullScreen=""
                    loading="lazy">
                </Iframe>
                <Form/>
            </div>
        </div>
    )
}

export default Map;

