import React, { useEffect, useState } from 'react';
import AvisGoogle from '../component/avisGoogle';
import AvisAdvisor from '../component/avisAdvisor';

const Avis = () => {
  const [reviewsGoogle, setReviewsGoogle] = useState([]);
  const [reviewsAdvisor, setReviewsAdvisor] = useState([]);
  const [detailsAdvisor, setDetailsAdvisor] = useState([]);

  const fetchData = async (url) => {
    const cache = await caches.open('my-cache');
    const cachedResponse = await cache.match(url);

    if (cachedResponse) {
      const data = await cachedResponse.json();
      return data;
    } else {
      const response = await fetch(url);
      const clonedResponse = response.clone();
      const data = await response.json();
      cache.put(url, clonedResponse);
      return data;
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [dataGoogle, dataAdvisor, dataDetails] = await Promise.all([
          fetchData('https://borsalino-392514.ew.r.appspot.com/google-reviews'),
          fetchData('https://borsalino-392514.ew.r.appspot.com/advisor-reviews'),
          fetchData('https://borsalino-392514.ew.r.appspot.com/advisor-details')
        ]);

        setReviewsGoogle(dataGoogle);
        setReviewsAdvisor(dataAdvisor);
        setDetailsAdvisor(dataDetails);
      } catch (error) {
        console.error(error);
      }
    };

    fetchAllData();
  }, []);

  return (
    <div>
      <AvisGoogle reviewsG={reviewsGoogle}/>
      <AvisAdvisor reviewsA={reviewsAdvisor} detailsA={detailsAdvisor} />
    </div>
  );
};

export default Avis;