function Boissons() {
    return (
        <div className="container-boissons">
            <div className="categorie">
                <div className="categorie-prd-qt">
                    <h1>Blanc</h1>
                    <div className="quantity">
                        <span className="qt">37,5 cl</span>
                        <span className="qt">75 cl</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Portugal, Vinho Verde DOC Aveleda</span>
                        <span>Frais, léger et citronné.</span>
                    </div>
                    <div className="qt-1">
                        <span>-</span>
                    </div>
                    <div className="qt-2">
                        <span>18</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Italie, Orvieto Classico DOC La Carraia</span>
                        <span>Belle minéralité volcanique, notes de pomme.</span>
                    </div>
                    <div className="qt-1">
                        <span>-</span>
                    </div>
                    <div className="qt-2">
                        <span>18</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Bourgogne Côte Châlonnaise AOC Chardonnay “Millebuis”</span>
                        <span>Tendre et plein, délicatement toasté.</span>
                    </div>
                    <div className="qt-1">
                        <span>12</span>
                    </div>
                    <div className="qt-2">
                        <span>22</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Sancerre AOC Les Broux</span>
                        <span>Bien sec, vif et long en bouche, au nez de pamplemousse.</span>
                    </div>
                    <div className="qt-1">
                        <span>-</span>
                    </div>
                    <div className="qt-2">
                        <span>26</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Côtes du Rhône Domaine Compte de LAUZE</span>
                    </div>
                    <div className="qt-1">
                        <span>-</span>
                    </div>
                    <div className="qt-2">
                        <span>24</span>
                    </div>
                </div>
            </div>
            <div className="categorie">
                <div className="categorie-prd-qt">
                    <h1>Rosés</h1>
                    <div className="quantity">
                        <span className="qt">37,5 cl</span>
                        <span className="qt">75 cl</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Château Sainte Roseline-Cru Classé</span>
                        <span>Léger, frais et croquant.</span>
                    </div>
                    <div className="qt-1">
                        <span>-</span>
                    </div>
                    <div className="qt-2">
                        <span>42</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Côtes de Provence AOC La Santonnière</span>
                        <span>Souple, frais et fruité.</span>
                    </div>
                    <div className="qt-1">
                        <span>9.5</span>
                    </div>
                    <div className="qt-2">
                        <span>18</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Côtes de Provence AOC M de Minuty</span>
                        <span>Frais et velouté comme une pêche.</span>
                    </div>
                    <div className="qt-1">
                        <span>-</span>
                    </div>
                    <div className="qt-2">
                        <span>35</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Italie, Bardolino Chiaretto D.O.C</span>
                        <span>Structuré, gourmand et fruité, à la robe rose fuschia assez pâle.</span>
                    </div>
                    <div className="qt-1">
                        <span>9.5</span>
                    </div>
                    <div className="qt-2">
                        <span>18</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Portugal, Lancers Azeitão</span>
                    </div>
                    <div className="qt-1">
                        <span>-</span>
                    </div>
                    <div className="qt-2">
                        <span>18</span>
                    </div>
                </div>
            </div>
            <div className="categorie">
                <div className="categorie-prd-qt">
                    <h1>Rouges</h1>
                    <div className="quantity">
                        <span className="qt">37,5 cl</span>
                        <span className="qt">75 cl</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Bordeaux AOC Chapelle de Brivazac</span>
                        <span>Des tannins croquants, nez de fruits rouges frais, de cerise.</span>
                    </div>
                    <div className="qt-1">
                        <span>-</span>
                    </div>
                    <div className="qt-2">
                        <span>20</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Bourgueil AOC Prestige et Tradition</span>
                        <span>Frais et croquant, nez de bois de ronce.</span>
                    </div>
                    <div className="qt-1">
                        <span>11</span>
                    </div>
                    <div className="qt-2">
                        <span>19</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Côtes de Bourg AOC Château de Barbe</span>
                        <span>Charnu et enrobé, nez de cassis.</span>
                    </div>
                    <div className="qt-1">
                        <span>-</span>
                    </div>
                    <div className="qt-2">
                        <span>22</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Côtes du Rhône-Villages AOC Domaine de la Renjarde</span>
                        <span>Plein, rond et velouté fruit rouge et truffe blanche.</span>
                    </div>
                    <div className="qt-1">
                        <span>12.5</span>
                    </div>
                    <div className="qt-2">
                        <span>22</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Brouilly AOC Château de Corcelles</span>
                        <span>Gourmand et fruité, nez de baies rouges.</span>
                    </div>
                    <div className="qt-1">
                        <span>12.5</span>
                    </div>
                    <div className="qt-2">
                        <span>23</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Saint-Estèphe AOC Château Beau Site Haut-Vignoble</span>
                        <span>Dense et charpenté, nez boisé, notes balsamiques.</span>
                    </div>
                    <div className="qt-1">
                        <span>-</span>
                    </div>
                    <div className="qt-2">
                        <span>39</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Haut-Médoc AOC Château d’Arcins</span>
                    </div>
                    <div className="qt-1">
                        <span>-</span>
                    </div>
                    <div className="qt-2">
                        <span>32</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Italie, Valpolicella DOC Bertani</span>
                        <span>Souple et frais, nez de cerises et de kirsch.</span>
                    </div>
                    <div className="qt-1">
                        <span>11.5</span>
                    </div>
                    <div className="qt-2">
                        <span>22</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Italie, Montepulciano DOC Toscane</span>
                        <span>Riche et plein, nez de fruits confits et d’épices.</span>
                    </div>
                    <div className="qt-1">
                        <span>-</span>
                    </div>
                    <div className="qt-2">
                        <span>19</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Italie, Chianti Classico DOCG Terre del Palio</span>
                        <span>Des tannins soyeux, nez de boisé de cèdre.</span>
                    </div>
                    <div className="qt-1">
                        <span>12.5</span>
                    </div>
                    <div className="qt-2">
                        <span>24</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Espagne, Marqués de Riscal Reserva, Rioja</span>
                        <span>Nez de griotte, de framboise, tannins veloutés.</span>
                    </div>
                    <div className="qt-1">
                        <span>-</span>
                    </div>
                    <div className="qt-2">
                        <span>36</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Portugal, Vinho Regional Alentejano Monte Velho, Esporão-</span>
                    </div>
                    <div className="qt-1">
                        <span>-</span>
                    </div>
                    <div className="qt-2">
                        <span>20</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Portugal, Alentejo DOC Tapada de Villar Reserva</span>
                    </div>
                    <div className="qt-1">
                        <span>-</span>
                    </div>
                    <div className="qt-2">
                        <span>37</span>
                    </div>
                </div>
            </div>
            <div className="categorie">
                <div className="categorie-prd-qt">
                    <h1>Vins en pichets</h1>
                    <div className="quantity">
                        <span className="qt">25 cl</span>
                        <span className="qt">50 cl</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Vino da Tavola Vernini</span>
                        <span>rouge, blanc ou rosé</span>
                    </div>
                    <div className="qt-1">
                        <span>4</span>
                    </div>
                    <div className="qt-2">
                        <span>7</span>
                    </div>
                </div>
            </div>
            <div className="categorie">
                <h1 className="margin">Boissons Chaudes</h1>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Café</span>
                    </div>
                    <div className="qt-1">
                        <span>2</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Thé, Infusion, Cappuccino</span>
                    </div>
                    <div className="qt-1">
                        <span>3</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Irish Coffee</span>
                    </div>
                    <div className="qt-1">
                        <span>10</span>
                    </div>
                </div>
            </div>
            <div className="categorie">
                <div className="categorie-prd-qt">
                    <h1 className="color">Boissons Fraiches</h1>
                    <div className="quantity">
                        <span className="qt">50 cl</span>
                        <span className="qt">100 cl</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Evian, Badoit, San Pellegrino</span>
                    </div>
                    <div className="qt-1">
                        <span>3</span>
                    </div>
                    <div className="qt-2">
                        <span>4.5</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Coca-Cola 33cl, Orangina, Schweppes, Jus de fruits</span>
                    </div>
                    <div className="qt-1">
                        <span>3</span>
                    </div>
                </div>
                
            </div>
            <div className="categorie">
                <h1 className="margin">Apéritifs</h1>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Cocktail maison 12cl</span>
                    </div>
                    <div className="qt-1">
                        <span>6</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Americano maison 12cl</span>
                    </div>
                    <div className="qt-1">
                        <span>7</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Coupe de champagne AOC 14cl</span>
                    </div>
                    <div className="qt-1">
                        <span>8</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Kir Royal 14cl</span>
                    </div>
                    <div className="qt-1">
                        <span>8</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Apéritif de marques 5cl, Ricard 2cl</span>
                    </div>
                    <div className="qt-1">
                        <span>4.5</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Kir au vin blanc 14cl</span>
                    </div>
                    <div className="qt-1">
                        <span>4</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Gin Tonic, Whisky Coca, Cuba Libre 4cl d’alcool</span>
                    </div>
                    <div className="qt-1">
                        <span>7.5</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Spritz Aperol</span>
                    </div>
                    <div className="qt-1">
                        <span>8.5</span>
                    </div>
                </div>
            </div>
            <div className="categorie">
                <h1 className="margin">Whiskies</h1>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">J&B 4cl</span>
                    </div>
                    <div className="qt-1">
                        <span>6.5</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Jack Daniel’s 4cl</span>
                    </div>
                    <div className="qt-1">
                        <span>7.5</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Cardhu 4cl</span>
                    </div>
                    <div className="qt-1">
                        <span>7.5</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Oban 4cl</span>
                    </div>
                    <div className="qt-1">
                        <span>11</span>
                    </div>
                </div>
            </div>
            <div className="categorie">
                <h1 className="margin">Bières</h1>
                <h2 className="categorieRed">Bouteilles</h2>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Heineken 25cl</span>
                    </div>
                    <div className="qt-1">
                        <span>4</span>
                    </div>
                </div>
                <br></br>
                <div className="categorie-prd-qt">
                    <h2 className="red">Pressions</h2>
                    <div className="quantity">
                        <span className="qt">25 cl</span>
                        <span className="qt">50 cl</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span className="boissons">Stella Artois</span>
                    </div>
                    <div className="qt-1">
                        <span>3.5</span>
                    </div>
                    <div className="qt-2">
                        <span>6</span>
                    </div>
                </div>
                <div className="categorie-prd">
                    <div className="produit">
                        <span >Leffe</span>
                    </div>
                    <div className="qt-1">
                        <span>4</span>
                    </div>
                    <div className="qt-2">
                        <span>7.5</span>
                    </div>
                </div>
            </div>
            <div className="categorie-partage">
                <div className="border">
                    <span>À Partager</span>
                    <span className="red">Les Ardoises 2 à 4 personnes 15€</span>
                    <span>Selon arrivage</span>
                </div>
            </div>
        </div>
    )
}

export default Boissons