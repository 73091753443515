import React from "react"
import Plats from "../component/plats"
import Boissons from "../component/boissons"

function Menu() {
    return (
        <div>
            <Plats/>
            <Boissons/>
        </div>
    )
}

export default Menu