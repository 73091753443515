export const handleScrollLeft = (container) => {
    const containerWidth = container.offsetWidth;
    let scrollAmount;
    if (window.innerWidth <= 1200) {
      scrollAmount = containerWidth; // Utiliser 100% de la largeur du conteneur
    } else {
      scrollAmount = containerWidth * 0.5; // Utilisez le pourcentage souhaité ici
    }
    container.scrollBy({
      left: -scrollAmount,
      behavior: 'smooth',
    });
};
  
export const handleScrollRight = (container) => {
    const containerWidth = container.offsetWidth;
    let scrollAmount;
    if (window.innerWidth <= 1200) {
        scrollAmount = containerWidth; // Utiliser 100% de la largeur du conteneur
    } else {
        scrollAmount = containerWidth * 0.5; // Utilisez le pourcentage souhaité ici
    }
    container.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
    });

    // Vérifiez si vous avez atteint la fin de la liste
    if (container.scrollLeft + containerWidth >= container.scrollWidth) {
        // Revenez au début de la liste
        container.scrollLeft = 0;
    }
};
  
export const handleScrollAuto = (containerRef) => {
    const container = containerRef.current;
    if (container) {
        const containerWidth = container.offsetWidth;
        let scrollAmount;
        if (window.innerWidth <= 1200) {
            scrollAmount = containerWidth; // Utiliser 100% de la largeur du conteneur
        } else {
            scrollAmount = containerWidth * 0.5; // Utilisez le pourcentage souhaité ici
        }
        container.scrollBy({
            left: scrollAmount,
            behavior: 'smooth',
        });

        // Vérifiez si vous avez atteint la fin de la liste
        if (container.scrollLeft + containerWidth >= container.scrollWidth) {
        // Revenez au début de la liste
        container.scrollLeft = 0;
        }
    }
};
  