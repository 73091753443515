import React from 'react';

const AvisItemA = ({ review }) => {
  return (
    <div>
      <div className="header-avis">
        <img src={review.user.avatar.original} alt="" />
        <div className="name-date">
          <p>{review.user.username}</p>
          <p>{review.travel_date}</p>
        </div>
      </div>
      <div className="rating-text">
        <img src={review.rating_image_url} alt="" />
        <p>{review.text}</p>
      </div>
    </div>
  );
};

export default AvisItemA;