import { useState } from "react";
import { useNavigate } from "react-router-dom";

function AdminLog({ onLogin }) {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const DataAdmin = {
      username,
      password,
    };

    console.log(DataAdmin);

    try {
      const response = await fetch("https://borsalino-392514.ew.r.appspot.com/admin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(DataAdmin),
      });
      const data = await response.json();
      console.log(data);
      const token = data.token;
      localStorage.setItem("token", token);
      onLogin();
      navigate("/admin-page");
    } catch (error) {
      console.error(error);
      alert("nom d'utilisateur ou mot de passe incorrect");
    }
  };

  return (
    <div className="container-form-log">
      <form onSubmit={handleSubmit}>
        <label>
          Nom d'utilisateur:
          <input
            className="input"
            type="text"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
        </label>
        <label>
          Mot de passe:
          <input
            className="input"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </label>
        <button className="bouton" type="submit">
          Se connecter
        </button>
      </form>
    </div>
  );
}

export default AdminLog;
