function Plats() {
        return (
            <div className="container-menu">
                <div className="categorie">
                    <h1>Entrées</h1>
                    <div className="categorie-direction">
                        <span className="plats">Crottin de Chavignol au miel sur nid de roquette</span>
                        <span className="flex-end">9</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Mozzarella di bufala balsamique de Modène, huile d’olive et tomates cerises,</span>
                        <span className="flex-end">9</span>
                    </div>
                    <div className="categorie-direction">
                        <div className="entree">
                            <span className="plats-entree">Salade Périgourdine salade, foie gras de canard maison, magret de canard fumé,</span>
                            <span className="plats-entree">gésier de canard confit et toasts tièdes</span>
                        </div>
                        <span className="flex-end">9</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Carpaccio de boeuf lamelles de cornichons et champignons de Paris, copeaux de parmesan, huile d’olive, basilic</span>
                        <span className="flex-end">10</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Foie gras de canard maison au miel, Porto, Armagnac et toasts tièdes</span>
                        <span className="flex-end">14</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Escargots de Bourgogne et son beurre persillé</span>
                        <div className="price">
                            <span className="size">les 6&emsp;&emsp;7</span>
                            <span className="size">les 12&emsp;&ensp;12</span> 
                        </div>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Salade de gésiers</span>
                        <span className="flex-end">9</span>
                    </div>
                </div>
    
                <div className="categorie">
                    <h1>Poissons</h1>
                    <div className="categorie-direction">
                        <span className="plats">Duo de Saint-Jacques et gambas au piment d’Espelette riz pilaf</span>
                        <span className="flex-end">19.5</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Pavé de saumon rôti beurre monté citronné, tagliatelles</span>
                        <span className="flex-end">18</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Tartare de saumon aux fines herbes pois gourmands et tomates cerises</span>
                        <span className="flex-end">18</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Gambas rôties flambées au Cognac</span>
                        <span className="flex-end">19.5</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Saint-Jacques poêlées au beurre persillé</span>
                        <span className="flex-end">19.5</span>
                    </div>
                </div>
    
                <div className="categorie">
                    <h1>Viandes</h1>
                    <div className="categorie-direction">
                        <span className="plats">Escalope Milanaise panée</span>
                        <span className="flex-end">16</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Escalope aux champignons tagliatelles</span>
                        <span className="flex-end">17</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Osso Buco à la Milanaise riz</span>
                        <span className="flex-end">17</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Saltimbocca Alla Romana</span>
                        <span className="flex-end">18</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Steak Tartare Borsalino</span>
                        <span className="flex-end">16</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Francesinha (spécialitée portugaise)</span>
                        <span className="flex-end">15</span>
                    </div>
                    <h2>Grillades</h2>
                    <div className="categorie-direction">
                        <span className="plats">La pièce du Borsalino entrecôte XXL (+/-500g)</span>
                        <span className="flex-end">24</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Entrecôte (+/-300g)</span>
                        <span className="flex-end">18</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Côte de boeuf (+/-1,3kg), pour 2 (5€ par personne supplémentaire), pommes sarladaises</span>
                        <span className="flex-end">49</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Picanha Black Angus à volonté (sur commande) sauce aïoli et sauce tartare maison, salade et frites Par personne</span>
                        <span className="flex-end">26</span>
                    </div>
                    <div className="categorie-direction-column">
                        <span>Garniture salade ou frites ou haricots verts</span>
                        <span>Supplément sauces maison tartare, aïoli, poivre, Gorgonzola, échalote &emsp; +2.5</span>
                    </div>
                </div>
    
                <div className="categorie">
                    <h1>Pates</h1>
                    <div className="categorie-direction">
                        <span className="plats">Spaghettis à la Bolognaise</span>
                        <span className="flex-end">10</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Tagliatelles à la Carbonara crème fraîche, lardons, oeuf</span>
                        <span className="flex-end">11</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Tagliatelles au saumon</span>
                        <span className="flex-end">11</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Penne alla Puttanesca huile d’olive, anchois, câpres, tomates, ail, olives, piment</span>
                        <span className="flex-end">11</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Spaghettis all’ Amatriciana tomates fraîches, lardons, oignons, huile d’olive, ail, basilic frais</span>
                        <span className="flex-end">11</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Penne aux quatre fromages</span>
                        <span className="flex-end">11</span>
                    </div>
                </div>
    
                <div className="categorie">
                    <h1>Pizzas</h1>
                    <div className="categorie-direction">
                        <span className="plats">Borsalino tomate, mozzarella, lardons, chorizo, champignons, oeuf, origan</span>
                        <span className="flex-end">12</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Al Tonno tomate, mozzarella, thon, oeuf, origan</span>
                        <span className="flex-end">12</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Orientale tomate, mozzarella, poivrons, merguez, oeuf, origan</span>
                        <span className="flex-end">12</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Norvégienne tomate, mozzarella, saumon, crème fraîche</span>
                        <span className="flex-end">14</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Calzone (soufflé) tomate, mozzarella, jambon, oeuf</span>
                        <span className="flex-end">11</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Quatre Fromages tomate, mozzarella, gorgonzola, camembert, chèvre, origan</span>
                        <span className="flex-end">12</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Bologna tomate, mozzarella, viande hachée, oeuf, origan</span>
                        <span className="flex-end">12</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Parma tomate, mozzarella, jambon d’Italie, gorgonzola, roquette, tomates cerises</span>
                        <span className="flex-end">14</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Napolitaine tomate, mozzarella, anchois, câpres, origan</span>
                        <span className="flex-end">11</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Régina tomate, mozzarella, jambon, champignons, origan</span>
                        <span className="flex-end">11</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Végétarienne tomate, mozzarella, poivrons, champignons, oignons, roquette, tomates cerises, origan</span>
                        <span className="flex-end">12</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Marguarita tomate, mozzarella, olive, origan</span>
                        <span className="flex-end">10</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Quatre Saisons tomate, mozzarella, jambon, champignons, tomates cerises, roquette, origan</span>
                        <span className="flex-end">12</span>
                    </div>
                    <div className="categorie-direction">
                        <span className="plats">Pescatore tomate, moules, calamars, crevettes, ail, persil</span>
                        <span className="flex-end">14</span>
                    </div>
                </div>
    
                <div className="categorie-formule">
                    <h1>Formules</h1>
                    <div className="formule-direction">
                        <div className="formule">
                            <span className="brown">L’EXPRESS 16€</span>
                            <br></br>
                            <span className="brown">servi uniquement le midi</span>
                            <span className="brown">du lundi au vendredi, hors jours fériés</span>
                            <br></br>
                            <span>Buffet d’entrée à volonté</span>
                            <span><span className="brown">+</span> Plat du jour au choix</span>
                            <span><span className="brown">ou</span> Pâtes au choix</span>
                            <span><span className="brown">ou</span> Pizza au choix <span className="brown">(supplément +3€)</span></span>
                            <span><span className="brown">+</span> Dessert du jour</span>
                            <span><span className="brown">+</span> Vin 25cl</span>
                        </div>
                        <div className="formule">
                            <span className="brown">MENU ENFANT 9€</span>
                            <br></br>
                            <span className="brown">enfant de -12 ans</span>
                            <br></br>
                            <span>Steak haché viande fraîche <span className="brown">ou</span> Nuggets frais</span>
                            <span>servi avec frites, pâtes <span className="brown">ou</span> haricots verts</span>
                            <span><span className="brown">+</span> Glace 2 boules</span>
                            <span><span className="brown">ou</span> Mousse au chocolat</span>
                            <span><span className="brown">ou</span> Crème caramel</span>
                            <span><span className="brown">+</span> Eau minérale, Soda 25cl</span>
                            <span><span className="brown">ou</span> Jus de fruit 25cl</span>
                        </div>
                        <div className="formule">
                            <span className="brown">LA BORSALINO 23€50</span>
                            <br></br>
                            <span>Salade de gésiers <span className="brown">ou</span> 6 Escargots</span>
                            <span><span className="brown">ou</span> Tomates mozzarella</span>
                            <span><span className="brown">+</span> Entrecôte <span className="brown">ou</span> Saumon au beurre blanc</span>
                            <span><span className="brown">ou</span> Pizza au choix ou Pâtes au choix</span>
                            <span><span className="brown">+</span> Dessert au choix</span>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Plats;