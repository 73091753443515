import React, { useRef, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Logo from "../assets/google-logo.svg";
import AvisItem from './avisItemG';
import { handleScrollLeft, handleScrollRight } from '../utils/scrollUtils';

const AvisGoogle = ({ reviewsG }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const extendedReviews = [...reviewsG, ...reviewsG];
    container.innerHTML = ''; // Efface le contenu initial

    extendedReviews.forEach((review) => {
      const listItem = document.createElement('li');
      listItem.className = 'avis';

      createRoot(listItem).render(<AvisItem review={review} />);

      container.appendChild(listItem);
    });

    const scrollInterval = setInterval(() => {
      const container = containerRef.current;
      if (container) {
        const containerWidth = container.offsetWidth;
        let scrollAmount;
        if (window.innerWidth <= 1200) {
          scrollAmount = containerWidth; // Utiliser 100% de la largeur du conteneur
        } else {
          scrollAmount = containerWidth * 0.5; // Utilisez le pourcentage souhaité ici
        }
        container.scrollBy({
          left: scrollAmount,
          behavior: 'smooth',
        });

        // Vérifiez si vous avez atteint la fin de la liste
        if (container.scrollLeft + containerWidth >= container.scrollWidth) {
          // Revenez au début de la liste
          container.scrollLeft = 0;
        }
      }
    }, 3500);

    return () => {
      clearInterval(scrollInterval);
    };
  }, [reviewsG]);

  return (
    <div className="container">
      <div className='container-google'>
        <img className="google-logo" src={Logo} alt="" />
        <div className='stars'>
          <span className="ti-star f"></span>
          <span className="ti-star f"></span>
          <span className="ti-star f"></span>
          <span className="ti-star f"></span>
          <span className="ti-star h"></span>
        </div>
      </div>
      <div className="container-avis">
        <button className="scroll-button left" onClick={() => handleScrollLeft(containerRef.current)}>
          <FaChevronLeft />
        </button>
        <ul className="avis-list scroll-container" ref={containerRef}>
          {reviewsG.map((review, index) => (
            <AvisItem key={index} review={review} />
          ))}
        </ul>
        <button className="scroll-button right" onClick={() => handleScrollRight(containerRef.current)}>
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default AvisGoogle;
