import React, { useState } from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
// COMPONENT
import Navbar from "./component/navbar";
// PAGES
import Accueil from "./layout/accueil"
import Menu from "./layout/menu";
import Avis from "./layout/avis";
// import Evenement from "./layout/evenement"
import AdminLog from "./layout/admin-log"
import AdminPage from "./layout/admin-page"
// STYLES

import "./styles/global/global.css";
import "./styles/avis/google.css"
import "./styles/map.css";
import "./styles/accueil/accueil.css";
import "./styles/menu/plats.css";
import "./styles/menu/boissons.css";
import "./styles/navbar/navbar.css";
import "./styles/form.css"

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('token');
  };

  return (
    <div>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path= "/menu" element={<Menu/>} /> 
        {/* <Route path= "/evenement" element={<Evenement/>} /> */}
        <Route path= "/avis" element={<Avis/>} />
        <Route path="/admin-log" element={<AdminLog onLogin={handleLogin} />} />
        <Route path="/admin-page" element={isLoggedIn ? <AdminPage onLogout={handleLogout} /> : <Navigate to="/admin-log"/> } />
      </Routes>
    </div>
  );
}

export default App;
