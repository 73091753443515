import React from 'react';

const AvisItemG = ({ review }) => {
  return (
    <div>
      <div className="header-avis">
        <img src={review.profile_photo_url} alt="" />
        <div className="name-date">
          <p>{review.author_name}</p>
          <p>{review.relative_time_description}</p>
        </div>
      </div>
      <div className="rating-text">
        <p className="rating">
          {Array.from({ length: 5 }).map((_, index) => (
            <span
              key={index}
              className={index < review.rating ? 'ti-star f' : 'ti-star e'}
            ></span>
          ))}
        </p>
        <p>{review.text}</p>
      </div>
    </div>
  );
};

export default AvisItemG;