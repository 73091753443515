import React, { useState } from "react";

const ReservationForm = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);

  const textareaRef = React.useRef(null);

  const handleInput = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const reservationData = {
      name,
      phone,
      subject,
      message,
    };

    try {
      const response = await fetch("https://borsalino-392514.ew.r.appspot.com/reservation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reservationData),
      });

      if (!response.ok) {
        throw new Error("Erreur lors de l'envoi de la réservation.");
      }

      alert("Réservation envoyée avec succès !");
    } catch (error) {
      console.error(error);
      setError("Erreur lors de l'envoi de la réservation.");
    }
  };

  return (
    <div className="container-form">
      <form onSubmit={handleSubmit}>
        <div className="direction">
          <label>
            <input
              type="text"
              className="input-accueil"
              placeholder="Nom & prénom *"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </label>
          <br />
          <label>
            <input
              type="tel"
              className="input-accueil"
              placeholder="Numéro de téléphone *"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </label>
        </div>
        <br />
        <label>
          <input
            type="text"
            className="input-accueil"
            placeholder="Sujet du message *"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </label>
        <br />
        <label>
          <textarea
            type="text"
            className="autoresize-textarea"
            placeholder="Votre message *"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onInput={handleInput}
            ref={textareaRef}
          />
        </label>
        <br />
        {error && <p className="error">{error}</p>}
        <button className="bouton" type="submit">
          Envoyer
        </button>
      </form>
    </div>
  );
};

export default ReservationForm;
