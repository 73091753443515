import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import Logo from "../assets/borsalino-logo.svg";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          <img src={Logo} className="logo" alt=""/>
        </Link>

        <div className="menu-icon" onClick={toggleMenu}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </div>

        <ul className={isOpen ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={closeMenu}>
              Accueil
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link to="/plats_du_jour" className="nav-links" onClick={closeMenu}>
              Plats du jour
            </Link>
          </li> */}
          <li className="nav-item">
            <Link to="/menu" className="nav-links" onClick={closeMenu}>
              Carte
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/avis" className="nav-links" onClick={closeMenu}>
              Avis
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;

